import React from 'react';
import ReactDOM from 'react-dom';

import { Alert } from 'antd';

const AlertMessageContext = React.createContext({context: {}});

const AlertMessage = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <AlertMessageContext.Consumer>
      {(value) => (<Alert message={value.messageTitle || "Error"} description={value.messageText} type={value.messageType || "error"} showIcon closable onClose={hide} className={value.pageName + "-alert-message"} />)}
    </AlertMessageContext.Consumer>
  </React.Fragment>, document.body
) : null;

export { AlertMessage, AlertMessageContext };
