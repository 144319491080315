import { useState } from 'react';

const useAlert = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(isShowing ? false : true);
  }

  return {
    setIsShowing,
    isShowing,
    toggle,
  }
};

export default useAlert;

