import React, { useEffect, useState } from 'react';

import { Link } from "gatsby";

import Icons from "./icons";

import { toAlpha3T, toAlpha2 } from "./locale-service";

import { getPreferredLanguage, setPreferredLanguage } from "./lang-service";

import "./lang-select.css";

function LanguageSelect(props) {
  const [selected, setSelected] = useState(-1);
  const [languages, setLanguages] = useState((props.langs || []).reduce(
    (items, item, index, langs) => {
      let selectedItem = item;
      let selectedIndex = -1;
      if (langs.length - 1 === index) {
        selectedIndex = (item === props.lang) ? -1 : items.indexOf(toAlpha3T(props.lang));
        if (selectedIndex > -1) {
          return items.splice(selected, 1).concat(items.concat([toAlpha3T(item)]));
        } else {
          return [toAlpha3T(item)].concat(items);
        }
      }
      return items.concat([toAlpha3T(item)]);
    },
  []));
  const mouseOutHandler = (e) => {
    if (e.currentTarget === e.target) {
      // blur (self)
    }
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setSelected(-1);
    }
  }
  const blurHandler = (e) => {
    setSelected(-1);
  };
  const clickHandler = (e) => {
    setSelected(0);
  };
  useEffect(() => {
    if (Array.isArray(props.langs) && props.langs.indexOf(getPreferredLanguage()) === -1) {
      setPreferredLanguage('');
    }
  }, []);
  return (
    <div className="lang-sel-container">
      <div className={(selected > -1 ? "lang-sel lang-sel-show" : "lang-sel")} onMouseOut={mouseOutHandler} onBlur={blurHandler} onClick={clickHandler}>
        {languages.map((item, index) =>
          <Link onClick={(e) => setPreferredLanguage(toAlpha2(item))} to={(index === 0) ? '' : `/${toAlpha2(item)}${props.route[1]}`}
            className={(index === 0) ? "lang-sel-selected lang-sel-item" : (selected === -1) ? "lang-sel-hidden lang-sel-item" : "lang-sel-item"}>
            {item}{index === 0 ? (<span className="lang-sel-caret">&nbsp;&nbsp;<Icons type="down" /></span>) : <span />}
          </Link>
        )}
      </div>
    </div>
  );
}

export default LanguageSelect;

