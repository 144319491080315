import React from "react";
import { Modal, Form, Input } from "antd";

const { TextArea } = Input;

const ContactModalForm = ({ visible, onCancel, onCreate, form }) => {
  const { getFieldDecorator } = form;
  return (
    <Modal
      visible={visible}
      title="Alifa Cloud Contact"
      okText="Submit"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form className="login-contact-modal-form" layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please input your full name"
              }
            ]
          })(<Input placeholder="Enter your contact name" className="form-field-control" />)}
        </Form.Item>
        <Form.Item label="Email">
         {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please input your contact email"
              }
            ]
         })(<Input placeholder="Enter your contact email" className="form-field-control" />)}
        </Form.Item>
        <Form.Item label="Message">
          {getFieldDecorator("message", {
            rules: [
              {
                required: true,
                message: "Please input your message into the box"
              }  
            ]  
          })(<TextArea placeholder="Enter your message here to get in touch with us" className="form-textarea-field-control" rows="4" cols="50" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ContactModalFormWrapper = Form.create({ name: "contact-modal-form" })(ContactModalForm);

export default ContactModalFormWrapper;

