import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const BackgroundImage = () => {
  const [imgIndex, setImgIndex] = useState(Math.floor(Math.random() * 7 - 1) + 1);
  const imgs = useStaticQuery(graphql`
    query {
      placeholderImage1: file(relativePath: { eq: "cloudy-bg1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage2: file(relativePath: { eq: "cloudy-bg2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage3: file(relativePath: { eq: "cloudy-bg3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage4: file(relativePath: { eq: "cloudy-bg4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage5: file(relativePath: { eq: "cloudy-bg5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage6: file(relativePath: { eq: "cloudy-bg6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage7: file(relativePath: { eq: "cloudy-bg7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img id="login-bg-image"
      style={{position: "fixed",  width: "100vw", height: "100vh", zIndex: "-1" }} 
      fluid={[
        imgs.placeholderImage1.childImageSharp.fluid,
        imgs.placeholderImage2.childImageSharp.fluid,
        imgs.placeholderImage3.childImageSharp.fluid,
        imgs.placeholderImage4.childImageSharp.fluid,
        imgs.placeholderImage5.childImageSharp.fluid,
        imgs.placeholderImage6.childImageSharp.fluid,
        imgs.placeholderImage7.childImageSharp.fluid
      ][imgIndex]}
    />
  );
}

export default BackgroundImage;

