import React from "react";
import { Modal, Form, Input } from "antd";

const PasswordRecoveryModalForm = ({ visible, onCancel, onCreate, form }) => {
  const { getFieldDecorator } = form;
  return (
    <Modal
      visible={visible}
      title="Alifa Cloud Password Recovery"
      okText="Submit"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form className="login-password-recovery-modal-form" layout="vertical">
        <Form.Item label="Contact Email">
         {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                type: "email",
                message: "Please input your contact email"
              }
            ]
         })(<Input placeholder="Enter your contact email" className="form-field-control" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const PasswordRecoveryModalFormWrapper = Form.create({ name: "password-recovery-modal-form" })(PasswordRecoveryModalForm);

export default PasswordRecoveryModalFormWrapper;

